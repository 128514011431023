/* eslint-disable array-callback-return */
import { Button, message, Table } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./Portfolio.css";
import { sendBlipWppOrderMessage } from "../../services/blip-whatsapp";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import {
  getDNSConfigs,
  getShipmentById,
  updateOrder,
} from "../../services/orders";
import getUrlQueryParams from "../../utils/get-url-query-params";
import formattedDate from "../../utils/date-format";
import { phoneNumberFormat } from "../../utils/string-format";

function Portfolio() {
  const history = useHistory();

  const [shipmentId, setshipmentId] = useState();
  const [rawDatasource, setRawDatasource] = useState([]);
  const [dataSource, setDatasource] = useState([]);
  const [configs, setConfigs] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [loadingList, setLoadingList] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const tableDefaultSize = 10;

  const [formOrder, setFormOrder] = useState({
    productId: "",
    customerName: "",
    customerPhone: "",
    supervisorEmail: "",
    cluster: "",
    orders: [],
    sentDate: "",
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "Nome",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Telefone",
      dataIndex: "customerPhone",
      key: "customerPhone",
      render: (text) => {
        return phoneNumberFormat(text);
      },
    },
    {
      title: "Email Supervisor",
      dataIndex: "supervisorEmail",
      key: "supervisorEmail",
    },
    {
      title: "Cluster",
      dataIndex: "cluster",
      key: "cluster",
    },
    {
      title: "Pedido",
      dataIndex: "order",
      key: "order",
      render: (text, record, index) => {
        return (
          <div className="orders-scroll-list" key={index}>
            {record.orders.map((order, idx) => {
              return (
                <div
                  className={`table-item-list ${
                    record.orders.length !== idx + 1 ? "border-bottom" : ""
                  }`}
                  key={`item_${index}-${idx}`}
                >
                  <div>
                    <b>Id. produto:</b>&nbsp;{order.productId}
                  </div>
                  <div>
                    <b>Nome produto:</b>&nbsp;{order.productName}
                  </div>
                  <div>
                    <b>Quantidade:</b>&nbsp;{order.amount}
                  </div>
                  <div>
                    <b>Preço Unit.:</b>&nbsp;{order.unitPrice}
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Dt. Envio",
      dataIndex: "sentDate",
      key: "sentDate",
      render: (text) => {
        if (text && text.seconds) {
          return formattedDate(
            new Date(text.seconds * 1000),
            "DD/MM/YYYY HH:mm"
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => {
        return !record.sentDate ? (
          <div className="actions">
            <EditOutlined
              className="edit-icon button-icon"
              onClick={(e) => editOrder(e, record)}
            />
            <SendOutlined
              className="send-icon button-icon"
              onClick={(e) => sendOrder(e, record.id)}
            />
          </div>
        ) : (
          ""
        );
      },
    },
  ];

  const editOrder = (e, data) => {
    e.preventDefault();

    setFormOrder(data);

    setIsEdit(true);
  };

  const saveAndSendOrder = async (e) => {
    e.preventDefault();

    setLoadingOrder(true);

    formOrder.sentDate = new Date();

    await updateOrder(shipmentId, formOrder);

    await sendBlipWppOrderMessage(shipmentId, formOrder, configs.urlDomain);

    await fetchData();

    message.success("Pedido enviado ao cliente com sucesso!");

    goBack();

    setLoadingOrder(false);
  };

  const sendOrder = async (e, orderId) => {
    e.preventDefault();

    setLoadingOrder(true);

    const order = rawDatasource.find((o) => o.id === orderId);

    order.sentDate = new Date();

    await updateOrder(shipmentId, order);

    await sendBlipWppOrderMessage(shipmentId, order, configs.urlDomain);

    await fetchData();

    message.success("Pedido enviado ao cliente com sucesso!");

    goBack();

    setLoadingOrder(false);
  };

  const sendOrderBatch = async (e, orderId) => {
    e.preventDefault();

    const order = rawDatasource.find((o) => o.id === orderId);

    order.sentDate = new Date();

    await updateOrder(shipmentId, order);

    await sendBlipWppOrderMessage(shipmentId, order, configs.urlDomain);

    await fetchData();
  };

  const saveOrder = async (e) => {
    e.preventDefault();

    setLoadingOrder(true);

    await updateOrder(shipmentId, formOrder);

    await fetchData();

    message.success("Informações salvas com sucesso!");

    goBack();

    setLoadingOrder(false);
  };

  const sendAllOrders = async (e) => {
    setLoadingOrder(true);

    const batchSize = 10;
    const batches = [];

    for (let i = 0; i < dataSource.length; i += batchSize) {
      batches.push(dataSource.slice(i, i + batchSize));
    }

    const success = [];
    const errors = [];

    for await (const batch of batches) {
      await Promise.all(
        batch.filter((order) => {
          if (order && !order.sentDate) {
            return sendOrderBatch(e, order.id)
              .then(() => {
                success.push(order.id);
              })
              .catch((error) => {
                // Armazena os erros para apresentar ao usuário
                errors.push({
                  error: JSON.stringify(error),
                  orderId: order.id,
                });
              });
          }
        })
      );
    }

    setLoadingOrder(false);

    return message.success("Disparos efetuados com sucesso.");
  };

  const fetchData = async () => {
    try {
      setLoadingList(true);

      const data = await getShipmentById(getShipmentId());

      setRawDatasource(data);

      setDatasource(data);
      setLoadingList(false);
    } catch (error) {
      message.error("Ocorreu um erro ao listar os dados");
      return history.push("/");
    }
  };

  const fetchConfigs = async () => {
    const res = await getDNSConfigs();
    setConfigs(res);
  };

  const goBack = () => {
    setIsEdit(false);
  };

  const getShipmentId = () => {
    const params = getUrlQueryParams(history.location.search);
    const shipId = params.get("shipmentId");

    setshipmentId(shipId);
    return shipId;
  };

  useEffect(() => {
    (async () => {
      fetchConfigs();
      fetchData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <div className="form-card">
        {loadingList ? (
          <Loading />
        ) : (
          <>
            {isEdit ? (
              loadingOrder ? (
                <Loading />
              ) : (
                <>
                  <h2>Cliente: {formOrder.customerName}</h2>
                  <form>
                    <div className="fields">
                      <label>Nome:</label>
                      <input value={formOrder.customerName} disabled></input>
                    </div>

                    <div className="fields">
                      <label>Supervisor:</label>
                      <input value={formOrder.supervisorEmail} disabled></input>
                    </div>

                    <div className="fields">
                      <label>Cluster:</label>
                      <input value={formOrder.cluster} disabled></input>
                    </div>

                    <div className="fields">
                      <label>Telefone:</label>
                      <input
                        value={phoneNumberFormat(formOrder.customerPhone)}
                        onChange={(e) =>
                          setFormOrder({
                            ...formOrder,
                            customerPhone: phoneNumberFormat(e.target.value),
                          })
                        }
                      ></input>
                    </div>

                    <div className="fields">
                      <label>Pedido:</label>
                      {formOrder &&
                        formOrder.orders.length > 0 &&
                        formOrder.orders.map((order, index) => {
                          return (
                            <div className="order-item" key={index}>
                              <div className="fields">
                                <label className="mini-label">
                                  ID PRODUTO:
                                </label>
                                <input
                                  value={formOrder.orders[index].productId}
                                  onChange={(e) => {
                                    const rawFormOrders = formOrder;
                                    rawFormOrders.orders[index] = {
                                      ...order,
                                      productId: e.target.value,
                                    };

                                    setFormOrder({ ...rawFormOrders });
                                  }}
                                ></input>
                              </div>
                              <div className="fields">
                                <label className="mini-label">
                                  NOME PRODUTO:
                                </label>
                                <input
                                  value={formOrder.orders[index].productName}
                                  onChange={(e) => {
                                    const rawFormOrders = formOrder;
                                    rawFormOrders.orders[index] = {
                                      ...order,
                                      productName: e.target.value,
                                    };

                                    setFormOrder({ ...rawFormOrders });
                                  }}
                                ></input>
                              </div>
                              <div className="fields">
                                <label className="mini-label">
                                  QUANTIDADE:
                                </label>
                                <input
                                  value={formOrder.orders[index].amount}
                                  onChange={(e) => {
                                    const rawFormOrders = formOrder;
                                    rawFormOrders.orders[index] = {
                                      ...order,
                                      amount: e.target.value,
                                    };

                                    setFormOrder({ ...rawFormOrders });
                                  }}
                                ></input>
                              </div>
                              <div className="fields">
                                <label className="mini-label">
                                  PREÇO UNITÁRIO.:
                                </label>
                                <input
                                  value={formOrder.orders[index].unitPrice}
                                  onChange={(e) => {
                                    const rawFormOrders = formOrder;
                                    rawFormOrders.orders[index] = {
                                      ...order,
                                      unitPrice: e.target.value,
                                    };

                                    setFormOrder({ ...rawFormOrders });
                                  }}
                                ></input>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <Button
                      type="primary"
                      className="confirm-order"
                      onClick={(e) => saveAndSendOrder(e, formOrder.id)}
                    >
                      Salvar e enviar pedido
                    </Button>
                    <Button
                      type="primary"
                      className="save-order"
                      onClick={(e) => saveOrder(e, formOrder.id)}
                    >
                      Apenas salvar pedido
                    </Button>
                    <Button
                      type="ghost"
                      className="go-back"
                      onClick={(e) => goBack(e)}
                    >
                      Voltar
                    </Button>
                  </form>
                </>
              )
            ) : (
              <>
                <div
                  className="back-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/upload")}
                >
                  <ArrowLeftOutlined /> Voltar para o início
                </div>
                <div className="header-content">
                  <h2>Olá!</h2>
                  <Button
                    type="primary"
                    className="send-all"
                    onClick={(e) => sendAllOrders(e)}
                  >
                    Disparar todas as mensagens
                  </Button>
                </div>
                <p id="info">
                  Esta é a sua carteira de clientes, verifique os pedidos antes
                  de enviar. Caso seja necessário, edite o pedido antes de
                  enviá-lo ao cliente.
                </p>
                <div className="table-content">
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                      defaultCurrent: 1,
                      defaultPageSize: tableDefaultSize,
                    }}
                    rowKey={"id"}
                    key={"id"}
                  />
                </div>
              </>
            )}{" "}
          </>
        )}
      </div>
    </div>
  );
}

export default Portfolio;
