import { message } from "antd";

const formatShipmentData = (shipment) => {
  return shipment.map((customer) => {
    if (customer && !customer.orders) {
      throw message.error("O arquivo deve ter um campo chamado orders");
    }

    const rawOrders = customer.orders
      .replace(/(\r\n|\r)/gm, "")
      .split("_");

    const formattedOrders = rawOrders.map((order) => {
      if (
        order.startsWith("|") ||
        order.indexOf("||") > -1 ||
        order.indexOf("[") > -1 ||
        order.indexOf("]") > -1
      ) {
        throw message.error(
          "Formato de pedido inválido. Verifique as instruções para o campo 'orders' e tente novamente."
        );
      }

      if (
        order.indexOf(';') > -1
      ) {
        throw message.error(
          "Formato de arquivo inválido. Existe algum ponto e vírgula inserido em local errado. Verifique os dados e tente novamente."
        );
      }

      const arrOrder = order.split("|");

      if (arrOrder.length > 4) {
        throw message.error(
          "Formato de item inválido. Os campos do pedido precisam ser separados por um | ."
        );
      }

      return {
        productId: arrOrder[0],
        productName: arrOrder[1],
        amount: arrOrder[2],
        unitPrice: arrOrder[3],
      };
    });

    return {
      ...customer,
      orders: formattedOrders,
    };
  });
};

export default formatShipmentData;
