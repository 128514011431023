import { formatToTimeZone } from "date-fns-timezone";
import { addDays } from "date-fns";

const timeZone = "America/Sao_Paulo";

function formattedDate(date, format = "DD/MM/YYYY", diffDays = 0) {
  const subtractDays = addDays(date, diffDays);

  return formatToTimeZone(subtractDays, format, {
    timeZone,
  });
}

export default formattedDate;
