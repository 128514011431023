const hmlCredentials = {
  apiKey: "AIzaSyBcDWTEE3xe2GbcZCSNh0I1ciPICjgQUAM",
  authDomain: "villa-pedidos-hml.firebaseapp.com",
  projectId: "villa-pedidos-hml",
  storageBucket: "villa-pedidos-hml.appspot.com",
  messagingSenderId: "318742357019",
  appId: "1:318742357019:web:225c3eb57e0a942a5184be",
};

const prodCredentials = {};

export default function getFirebaseCredentials(projectId) {
  if (projectId === "villa-pedidos-prod") {
    return prodCredentials;
  } else if (projectId === "villa-pedidos-hml") {
    return hmlCredentials;
  } else {
    throw Error("Configuração não encontrada");
  }
}