import formattedDate from "./date-format";

function getNextBusinessDay() {
  const now = new Date();

  const today = now.getDay();

  const format = "DD/MM/YYYY";

  if (today === 5) {
    return formattedDate(now, format, 3);
  }

  if (today === 6) {
    return formattedDate(now, format, 2);
  }

  return formattedDate(now, format, 1);
}

export default getNextBusinessDay;
