import { useEffect, useState } from "react";
import "./UploadFile.css";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import {
  listShipments,
  insertOrders,
  getAuthConfigs,
} from "../../services/orders";
import csvToJson from "../../utils/csv-to-json";
import formatShipmentData from "../../utils/format-order-data";
import formattedDate from "../../utils/date-format";
import { message } from "antd";

function UploadFile() {
  const history = useHistory();

  const [shipmentsList, setShipmentsList] = useState([]);

  const [isAuth, setIsAuth] = useState(true);
  const [loadingList, setLoadingList] = useState(false);

  const [csvFile, setCsvFile] = useState();

  const submit = async () => {
    setLoadingList(true);

    const file = csvFile;
    const reader = new FileReader();

    reader.onload = async function (e) {
      const text = e.target.result;

      const objData = csvToJson(text);

      const formattedObj = formatShipmentData(objData);
      formattedObj.fileName = csvFile.name;

      insertOrders(formattedObj).then(
        (shipmentId) => {
          setLoadingList(false);
          history.push(`/carteira?shipmentId=${shipmentId}`);
        },
        (error) => {
          console.error(error);
          setLoadingList(false);
          throw message.error("Ocorreu um erro ao salvar os dados do arquivo.");
        }
      );
    };

    reader.readAsText(file);
  };

  const getShipmentsList = async () => {
    setLoadingList(true);
    const res = await listShipments();
    setShipmentsList(res);
    setLoadingList(false);
  };

  const checkIsAuth = async () => {
    const res = await getAuthConfigs();
    const psw = res.adminPassword;

    return new Promise((resolve, reject) => {
      const inputAnswer = prompt(
        "Informe a senha de administrador para acessar:"
      );

      if (inputAnswer === psw) {
        setIsAuth(true);
        return resolve(true);
      } else {
        alert("Senha incorreta. Tente novamente.");
        checkIsAuth();
      }
    });
  };

  const openShipmentData = async (shipmentId) => {
    return history.push(`/carteira?shipmentId=${shipmentId}`);
  };

  useEffect(() => {
    (async () => {
      getShipmentsList();
      if (!isAuth) {
        if (await checkIsAuth()) {
          setIsAuth(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-upload">
      {!isAuth ? (
        <div></div>
      ) : (
        <>
          <div className="form-card-upload margin-bottom-20">
            <div>
              <h3>Carregar arquivo</h3>
              <span>
                O .csv a ser enviado deve conter obrigatoriamente as seguintes
                colunas:
                <ul>
                  <li>userId (Cod usuário - equiv: codusur)</li>
                  <li>customerId (Cod cliente - equiv: codcli)</li>
                  <li>customerPhone (telefone do cliente)</li>
                  <li>customerName (nome do cliente)</li>
                  <li>orders (texto do pedido)</li>
                  <div>
                    <i>
                      OBS: O campo <b>"orders"</b> é texto e precisa ter o
                      seguinte formato (campos separados por um | ou barra) para
                      que a formatação seja reconhecida:
                      <br />
                      <b>Produto único:</b>
                      <br />- productId|productName|amount|unitPrice
                      <b>
                        <br />
                        Múltiplos produtos (produtos separados por underline _
                        ):
                      </b>
                      <br />-
                      productId|productName|amount|unitPrice_productId|productName|amount|unitPrice_productId|productName|amount|unitPrice
                    </i>
                  </div>
                  <li>supervisorEmail (e-mail do supervisor)</li>
                  <li>cluster (cluster do cliente)</li>

                  <li>branchId (Cod filial - equiv: codfilial)</li>
                  <li>
                    customerPlanId (Cod plano pagamento do cliente - equiv:
                    codplpag)
                  </li>
                  <li>chargeId (Cod cobrança - equiv: codcob)</li>
                  <li>saleOrderId (Tipo pedido de venda - equiv: condvenda)</li>
                  <li>orderNote (Observação do pedido - equiv: obs1)</li>
                </ul>
              </span>
              <input
                type="file"
                accept=".csv"
                id="input-file"
                onChange={(e) => {
                  setCsvFile(e.target.files[0]);
                }}
              />
              <button
                style={{ marginTop: "10px" }}
                className="confirm-order"
                onClick={(e) => {
                  e.preventDefault();

                  if (csvFile) submit();
                }}
              >
                Enviar arquivo
              </button>
            </div>
          </div>

          <div className="form-card-upload">
            {loadingList ? (
              <Loading />
            ) : (
              <div>
                <h3>Lista de arquivos</h3>
                {shipmentsList.length === 0 && (
                  <div>Nenhum arquivo enviado.</div>
                )}
                {shipmentsList.map((file, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`${idx > 0 ? "border-top" : ""} file-item`}
                      onClick={() => openShipmentData(file.id)}
                    >
                      {file.name} -{" "}
                      {formattedDate(
                        new Date(file.createdAt.seconds * 1000),
                        "DD/MM/YYYY HH:mm"
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default UploadFile;
