import firebase from "firebase";
import getFirebaseCredentials from "./firebaseCredentials";

const firebaseConfig = getFirebaseCredentials(
  `${process.env.REACT_APP_FIREBASE_PROJECT}`
);

console.log("project", process.env.REACT_APP_FIREBASE_PROJECT);

firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === "localhost") {
//   app.functions().useFunctionsEmulator("http://localhost:5001");
//   app.auth().useEmulator("http://localhost:9099");
//   app.firestore().settings({
//     host: "localhost:8080",
//     ssl: false,
//   });
// }

export const firestore = firebase.firestore();
