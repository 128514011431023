import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UploadFile from "./pages/upload-file/UploadFile";
import Portfolio from "./pages/portfolio/Portfolio";
import Cart from "./pages/cart/Cart";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route
          path={"/"}
          exact
          render={() => (window.location.href = "https://villacamarao.com.br/")}
        />
        <Route path={"/upload"} render={() => <UploadFile />} />
        <Route path={"/carteira"} render={() => <Portfolio />} />{" "}
        <Route path={"/carrinho"} render={() => <Cart />} />{" "}
      </Switch>
    </Router>
  );
}
