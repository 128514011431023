import axios from "axios";
import { firestore } from "../config/firebase.config";
import randomId from "../utils/random-id";

export async function insertOrders(dataArr) {
  return new Promise((resolve, reject) => {
    const shipmentId = randomId(5);

    firestore
      .collection("shipments")
      .doc(shipmentId)
      .set({ createdAt: new Date(), name: dataArr.fileName, id: shipmentId })
      .then(
        () => {
          delete dataArr.fileName;

          const promises = dataArr.map((data) => {
            const orderId = randomId(10);

            const item = data;
            item.id = orderId;

            console.log({item})

            return firestore
              .collection("shipments")
              .doc(shipmentId)
              .collection("orders")
              .doc(orderId)
              .set(item);
          });

          Promise.all(promises).then(
            () => {
              return resolve(shipmentId);
            },
            (error) => {
              console.error(error);
              return reject(error);
            }
          );
        },
        (error) => {
          console.error(error);
          return reject(error);
        }
      );
  });
}

export async function getOrder(shipmentId, orderId) {
  try {
    const doc = await firestore
      .collection("shipments")
      .doc(shipmentId)
      .collection("orders")
      .doc(orderId)
      .get();

    return doc.data();
  } catch (error) {
    console.error(error);
    throw new Error("Ocorreu um erro ao buscar o pedido.");
  }
}

export async function updateOrder(shipmentId, order) {
  try {
    await firestore
      .collection("shipments")
      .doc(shipmentId)
      .collection("orders")
      .doc(order.id)
      .set(order);

    return;
  } catch (error) {
    console.error(error);
    throw new Error("Ocorreu um erro ao atualizar o pedido.");
  }
}

export async function getShipmentById(shipmentId) {
  try {
    const doc = await firestore
      .collection("shipments")
      .doc(shipmentId)
      .collection("orders")
      .orderBy("customerName", "asc")
      .get();

    return doc.docs.map((item) => item.data());
  } catch (error) {
    console.error(error);
    throw new Error("Ocorreu um erro ao buscar os dados da remessa.");
  }
}

export async function listShipments() {
  try {
    const doc = await firestore
      .collection("shipments")
      .orderBy("createdAt", "desc")
      .get();

    return doc.docs.map((item) => item.data());
  } catch (error) {
    console.error(error);
    throw new Error("Ocorreu um erro ao listar as remessas.");
  }
}

export async function getDNSConfigs() {
  try {
    const doc = await firestore.collection("config").doc("dns").get();

    return doc.data();
  } catch (error) {
    console.error(error);
    throw new Error("Ocorreu um erro as configurações do sistema.");
  }
}

export async function getAuthConfigs() {
  try {
    const doc = await firestore.collection("config").doc("auth").get();

    return doc.data();
  } catch (error) {
    console.error(error);
    throw new Error("Ocorreu um erro as configurações do sistema.");
  }
}

export async function createConfirmatedOrder(order) {
  try {
    const dnsConfigs = await getDNSConfigs();

    const headers = {
      "Content-Type": "application/json",
    };

    const winthorApiKey = (await getAuthConfigs()).winthorApiKey;

    const body = {
      order,
      winthorApiKey
    };

    const res = await axios.post(`${dnsConfigs.urlLambda}`, body, {
      headers: headers,
    });

    return res.data;
  } catch (error) {
    throw new Error("Ocorreu um erro ao criar o pedido no sistema");
  }
}
