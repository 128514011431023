import { message } from "antd";
import axios from "axios";
import { v4 as uuid } from "uuid";
import getNextBusinessDay from "../utils/get-next-business-day";
import { validatePhone } from "../utils/string-format";

const BOT_TOKEN = "Key Ym90dmlsbGE6VHVRM01ZOGd5OHZ5Wm5pcldFRkQ=";
const BOT_NAMESPACE = "0599fb01_7837_41db_b14c_b76b76d49174";

const BOT_FLOW_ID = "68710691-3677-4cfd-a66f-4a43b5b05d1b";

const DEFAULT_URL = "https://http.msging.net";
const URL_COMMANDS = `${DEFAULT_URL}/commands`;
const URL_MESSAGES = `${DEFAULT_URL}/messages`;

const headers = {
  "Content-Type": "application/json",
  Authorization: BOT_TOKEN,
};

export async function sendBlipWppOrderMessage(shipmentId, order, domainUrl) {
  const wppId = await getCustomerWhatsappId(order.customerPhone);

  await updateCustomerData(
    wppId,
    order.customerName,
    `${order.supervisorEmail.replace("@", "%40")}@blip.ai`,
    order.cluster
  );

  await updateCustomerFlowState(
    wppId,
    BOT_FLOW_ID,
    "dac98573-f89a-4b2e-8061-2ba347c218a2"
  );

  const orderUrl = `https://${window.location.hostname}/carrinho?shipmentId=${shipmentId}&orderId=${order.id}`;

  const body = generateTemplateBody(wppId, "sugestao_pedido_url", [
    { type: "text", text: order.customerName },
    { type: "text", text: orderUrl },
  ]);

  const res = await axios.post(URL_MESSAGES, body, { headers: headers });

  return res;
}

export async function sendBlipWppConfirmationOrderNotification(
  customerName,
  customerPhone,
  erpOrderId
) {
  const wppId = await getCustomerWhatsappId(customerPhone);

  // await updateCustomerFlowState(
  //   wppId,
  //   BOT_FLOW_ID,
  //   "5208ec69-b526-4117-99ac-d23cfb14b76d"
  // );

  const body = generateTemplateBody(wppId, "notificacao_confirmacao_pedido", [
    { type: "text", text: customerName },
    { type: "text", text: erpOrderId.toString() },
    { type: "text", text: getNextBusinessDay() },
  ]);

  const res = await axios.post(URL_MESSAGES, body, { headers: headers });

  return res;
}

/*  Verifica se existe conta de Whatsapp para este
  número e retorna o identificador do Whatsapp */
export async function getCustomerWhatsappId(phone) {
  try {
    const body = {
      id: uuid(),
      to: "postmaster@wa.gw.msging.net",
      method: "get",
      uri: `lime://wa.gw.msging.net/accounts/+55${validatePhone(phone)}`,
    };

    const res = await axios.post(URL_COMMANDS, body, { headers: headers });

    if (!res) {
      throw Error("Whatsapp identifier not found");
    }

    return res.data.resource.identity;
  } catch (error) {
    throw error;
  }
}

/*  Gera o body da requisição */
function generateTemplateBody(
  customerWppIdentity,
  templateName,
  messageParams = []
) {
  return {
    id: uuid(),
    to: customerWppIdentity,
    type: "application/json",
    content: {
      type: "template",
      template: {
        namespace: BOT_NAMESPACE,
        name: templateName,
        language: {
          code: "pt_BR",
          policy: "deterministic",
        },
        components: [
          {
            type: "body",
            parameters: messageParams,
          },
        ],
      },
    },
  };
}

async function updateCustomerFlowState(
  userWppIdentity,
  flowIdentifier,
  stateId
) {
  try {
    const body = {
      id: uuid(),
      method: "set",
      uri: `/contexts/${userWppIdentity}/stateid@${flowIdentifier}`,
      type: "text/plain",
      resource: `${stateId}`,
    };

    const res = await axios.post(URL_COMMANDS, body, { headers: headers });

    if (!res) {
      throw Error("Whatsapp identifier not found");
    }

    return res.data;
  } catch (error) {
    throw error;
  }
}

async function updateCustomerData(
  contactIdentifier,
  name,
  supervisorMailAddress,
  cluster
) {
  try {
    const body = {
      id: uuid(),
      method: "set",
      uri: "/contacts",
      type: "application/vnd.lime.contact+json",
      resource: {
        identity: contactIdentifier,
        name: name,
        extras: {
          supMail: supervisorMailAddress,
          cluster: cluster,
        },
      },
    };

    const res = await axios.post(URL_COMMANDS, body, { headers: headers });

    if (!res) {
      throw Error("Whatsapp identifier not found");
    }

    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function openAttendanceTicket(customerIdentity) {
  const body = {
    id: uuid(),
    to: "postmaster@desk.msging.net",
    method: "set",
    uri: "/tickets",
    type: "application/vnd.iris.ticket+json",
    resource: {
      customerIdentity,
    },
  };

  const res = await axios.post(URL_COMMANDS, body, { headers: headers });

  if (res.data.status === "failure" && res.data?.reason?.code === 101) {
    throw message.error(
      "Você já possui um ticket de atendimento em aberto. Aguarde que em breve um agente irá te atender."
    );
  }

  if (res.data.status === "failure") {
    throw message.error(
      "Ocorreu um erro ao criar um ticket de atendimento. Tente novamente."
    );
  }

  return res.data.resource.id;
}

export async function assignTicketToAgent(ticketId, agentIdentity) {
  try {
    const body = {
      id: uuid(),
      to: "postmaster@desk.msging.net",
      method: "set",
      uri: "/tickets/change-status",
      type: "application/vnd.iris.ticket+json",
      resource: {
        id: ticketId,
        status: "Open",
        agentIdentity: `${agentIdentity.replace("@", "%40")}@blip.ai`,
      },
    };

    await axios.post(URL_COMMANDS, body, { headers: headers });
  } catch (error) {
    console.error(error);
    throw Error(
      "Ocorreu um erro ao criar o ticket de atendimento para o agente"
    );
  }
}

// 7e00cefc-edb6-4ac1-adc2-017f2ece380f
