import { useEffect, useState } from "react";
import "./Cart.css";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import logoVilla from "../../assets/img/villa-logo.png";
import {
  createConfirmatedOrder,
  getOrder,
  updateOrder,
} from "../../services/orders";
import getUrlQueryParams from "../../utils/get-url-query-params";
import { currencyFormat } from "../../utils/string-format";
import {
  assignTicketToAgent,
  getCustomerWhatsappId,
  openAttendanceTicket,
  sendBlipWppConfirmationOrderNotification,
} from "../../services/blip-whatsapp";
import { message } from "antd";
import formattedDate from "../../utils/date-format";

function Cart() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [shipmentId, setshipmentId] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOrderIdentifiers = () => {
    const params = getUrlQueryParams(history.location.search);

    const shipId = params.get("shipmentId");

    setshipmentId(shipId);

    return {
      shipmentId: shipId,
      orderId: params.get("orderId"),
    };
  };

  const fetchOrder = async () => {
    setLoading(true);

    const { orderId, shipmentId } = getOrderIdentifiers();
    const res = await getOrder(shipmentId, orderId);

    setData(res);

    setLoading(false);
  };

  const confirmOrder = async () => {
    try {
      setLoading(true);
      const order = data;
      const resGenerateOrder = await createConfirmatedOrder(order);

      if (!resGenerateOrder) {
        return message.error("Ocorreu um erro criar o pedido no sistema");
      }

      await sendBlipWppConfirmationOrderNotification(
        order.customerName,
        order.customerPhone,
        resGenerateOrder.pedidoerp
      );

      order.erpOrderId = resGenerateOrder.pedidoerp;
      order.confirmationDate = new Date();

      await updateOrder(shipmentId, order);
      setLoading(false);

      return message.success(
        "Pedido confirmado com sucesso! Você receberá uma notificação em seu Whatsapp com a data de entrega e o número do pedido"
      );
    } catch (error) {
      setLoading(false);
      throw message.error(
        "Ocorreu um erro confirmar o pedido. Tente novamente!"
      );
    }
  };

  const callConsultant = async () => {
    try {
      setLoading(true);
      const customerIdentity = await getCustomerWhatsappId(data.customerPhone);
      const ticketId = await openAttendanceTicket(customerIdentity);

      await assignTicketToAgent(ticketId, data.supervisorEmail);

      setLoading(false);

      message.error(
        "Certo! Em breve um consultor irá atender você via Whatsapp."
      );
    } catch (error) {
      setLoading(false);
      throw message.error("Ocorreu um contatar um consultor");
    }
  };

  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-centered">
      {loading ? (
        <Loading />
      ) : (
        <div className="content-cart">
          <div className="header-bar">
            <img id="logo-villa" src={logoVilla} alt="logo-villa" />
            <div>Olá, {data.customerName}!</div>
          </div>
          {data && data.erpOrderId ? (
            <div className="confirmed-order">
              {data.confirmationDate && data.confirmationDate.seconds ? (
                <>
                  <h2>
                    Seu pedido #{data.erpOrderId} já foi confirmado em nosso
                    sistema em{" "}
                    {formattedDate(data.confirmationDate.seconds * 1000)}.
                  </h2>
                  <br />
                  <div>
                    Em caso de dúvida, entre em contato com nossa Central de
                    Atendimento: 3003-8380
                  </div>
                </>
              ) : (
                <>
                  <h2>
                    Tudo certo, agora é só aguardar. A Villa Camarão agradece a
                    sua compra!
                  </h2>
                  <br />
                  <div>
                    Em caso de dúvida, entre em contato com nossa Central de
                    Atendimento: 3003-8380
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="section">
              <h2>Meu pedido:</h2>
              <div className="cart-card">
                {data &&
                  data?.orders?.length > 0 &&
                  data?.orders?.map((item, idx) => {
                    const index = idx + 1;
                    return (
                      <div
                        className={`table-item ${
                          index === data.length ? "last-table-item" : null
                        }`}
                        key={index}
                      >
                        <div className="item-identifier">
                          <div className="key">#{index}</div>
                          <div className="delete-icon">
                            {/* <DeleteOutlined></DeleteOutlined> */}
                          </div>
                        </div>
                        <div className="item-data">
                          <div className="key">
                            Nome produto:
                            <span className="value">{item.productName}</span>
                          </div>
                          <div className="key">
                            Quantidade:
                            <span className="value">{item.amount}</span>
                          </div>
                          <div className="key">
                            Preço Unit.:
                            <span className="value">{item.unitPrice}</span>
                          </div>
                          <div className="key">
                            Preço Tot.:
                            <span className="value">
                              {currencyFormat(
                                Number(item.amount) * Number(item.unitPrice)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="actions">
                <button id="send-button" onClick={confirmOrder}>
                  Confirmar pedido
                </button>
                <button id="sales-button" onClick={callConsultant}>
                  Quero falar com meu consultor de vendas
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Cart;
