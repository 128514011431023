export const currencyFormat = (value) => {
  if (typeof value === "string") value = Number(value);

  const currency = value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 3,
  });

  return currency.substring(0, currency.length - 1);
};

export const phoneNumberFormat = (phone) => {
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;

  const rawPhone = phone.replace(/[^0-9]/g, "").slice(0, 11);

  return rawPhone.replace(regex, "($1)$2-$3");
};

/*  Formata o telefone e remove os dígitos 
  e caracteres desnecessários */
export function validatePhone(phone) {
  phone = phone.replace(/\D/g, "");

  if (phone.length === 13 || phone.length === 12) {
    phone = phone.substring(2);
  }

  if (phone.length === 10) {
    phone = phone.slice(0, 2) + "9" + phone.slice(2);
  }

  if (phone.length !== 11) {
    throw Error("Formato de telefone inválido.");
  }

  return phone;
}
